.testimonial-2-content {
    background: $white;
}

.service-link li:first-child {
    color: $light-blue;
    font-weight: bold;
}

.about-title,
.single-service-text,
.testimonial-2-area {
    ol,
    ul {
        margin: 10px 0 25px 10px;
        li {
            color: #818181;
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
            list-style-type: disc;
            margin: 0 0 0 8px;
        }
    }
    ol li {
        list-style: decimal;
        ul li {
            list-style: disc;
        }
    }
}

.panel-heading h4 a {
    span {
        margin-left: 20px;
    }
    &.two-lines:after {
        margin-top: -26px;
    }
}

.single-service-text {
    h3,
    h5 {
        color: #575757;
    }
    h3 {
        font-size: 16px;
        margin: 25px 0;
    }
    h5 {
        font-size: 14px;
        margin: 15px 0 10px 0;
    }
}

.testimonial-2-area {
    p,
    ul {
        text-align: left;
    }
    p {
        width: 100%;
        margin: 30px 0 20px 0;
    }
}

.breadcrumb-banner-area {
    background-size: cover;
    &.pro {
        background-position: center 65%;
    }
    &.travaux {
        background-position: center 90%;
    }
    &.demenagement {
        background-position: bottom;
    }
    &.grenier {
        background-position: center 35%;
    }
    &.sinistre {
        background-position: center 70%;
    }
    &.vitre {
        background-position: center bottom;
    }
    &.contact {
        background-position: center 47%;
        background-size: 120%;
    }
    &.about {
        background-position: center 50%;
    }
    &.devis {
        background-size: 130%;
        background-position: center 10%;
    }
}

.about-img {
    overflow: hidden;
    img {
        height: 677px;
        max-width: none;
        transform: translateX(-17%);
    }
}