.contact-area .fa-facebook {
    border-radius: 30px;
    color: $light-blue;
    display: inline-block;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    margin-right: 5px;
    text-align: center;
    transition: all 0.3s ease 0s;
    width: 30px;
    background: $white;
    &:hover {
        background: #edf0f2;
        color: $dark-blue;
    }
}

.contact-area {
    padding-top: 120px;
    .row {
        @include breakpoint (medium) {
            display: flex;
            .contact-left-wrapper {
                height: 100%;
            }
        }
    }
}


#map {
    padding: 0 15px;
    filter: grayscale(100%);
    .mapouter {
        overflow: hidden;
    }
}

.contact-text span a {
    color: $white;
    transition: .3s;
    &:hover {
        color: $dark-blue;
    }
}

.contact-right-wrapper.mb-30 > p {
    margin-bottom: 50px;
}