a:hover, a:focus {
    color: $white;
}

#scrollUp {
    background: $dark-blue none repeat scroll 0 0;
    &:hover {
        background: $light-blue;
    }
}

textarea,
input {
    font-weight: 400 !important;
    color: $grey-darker !important;
    box-shadow: none !important;
    border: 0 !important;
    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $grey-darker !important;
        opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $grey-darker !important;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
        color: $grey-darker !important;
    }
}

.copyright p {
    color: #696969;
}

.breadcrumb-menu ul li,
.single-service-text h2,
.breadcrumb-text h1,
.main-menu ul li .sub-menu li a,
.service-link li a,
.panel-heading h4 a,
#contact-form textarea {
    text-transform: none;
}

ul {
    margin: 0;
}

.mean-container .mean-nav ul li a.mean-expand {
    padding: 7px 10px;
}

h1 {
    font-size: 65px;
}

// recaptcha google
.grecaptcha-badge {
    display: none;
}