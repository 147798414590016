.header-area {
    padding-top: 20px;
    padding-bottom: 20px;
    .header-logo a {
        display: block;
        width: 80px;
    }
}
.header-style-2 {
    nav ul {
        margin: 0;
    }
}
.header-wrapper {
    margin-top: 7px;
}

.header-style-2 .mean-container a.meanmenu-reveal {
    margin-top: -75px;
}

.col-md-7.col-sm-6.col-xs-6.text-right {
    height: 58px;
    @include breakpoint (large) {
        width: calc(100% - 214px - 195px);
    }
    a {
        display: inline-block;
        vertical-align: middle;
        color: $light-blue;
        @extend %transition-ease-long;
        @include breakpoint (large) {
            line-height: 58px;
        }
        strong {
            display: block;
            @include breakpoint (large) {
                display: inline-block;
            }
        } 
        &:hover {
            color: $dark-blue;
        }
    }
}
.col-md-3.hidden-sm.hidden-xs {
    height: 58px;
    width: 214px;
}

.mean-nav > ul {
    height: 204px;
}
.main-menu-area {
    // margin-top: -10px;
    .container .row {
        height: 40px;
    }
    .main-menu nav ul li {
        a {
            padding: 10px 0;
        }
        ul li a {
            padding: 10px 15px;
        }
    }
    .icon a {
        padding: 13px;
    }
}
.slider-wrapper {
    padding-top: 200px;
    padding-bottom: 200px;  
}

.footer-top-area {
    &.pb-70 {
        padding-top: 50px;
        padding-bottom: 30px;
    }
    .row {
        .footer-logo a {
            display: block;
            width: 80px;
            margin: 0 auto;
        }
        > div:nth-of-type(2) {
            text-align: center;
        }
        > div:nth-of-type(3) {
            text-align: center;
            .footer-icon {
                margin-top: 0;
                padding-left: 20px;
            }
        }
        @include breakpoint (medium) {
            display: flex;
            align-items: center;
            .footer-logo a {
                margin: 0;
            }
            .footer-icon {
                border-left: 2px solid #edf0f2;
            }
            .footer-wrapper.mb-30 {
                margin-bottom: 0;
                .footer-menu {
                    margin: 0;
                }
                .footer-logo {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.email {
    @include breakpoint (large) {
        margin-right: 10px;
    }
}

.tel,
.email {
    strong {
        margin-bottom: -4px;
    }
}