@import url('https://fonts.googleapis.com/css?family=Courgette|Montserrat:300,400,500,600,700,800,900');

$ff-base: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
$font: 'Montserrat';

// $ff-aller: aller_regular;
// $ff-aller-italic: aller_rg_italic;
// $ff-aller-regular-bold: aller_rg_bold;
// $ff-aller-bold-italic: aller_bold_italic;
// $ff-aller-light-italic: aller_light_italic;

// $ff-asap: 'Asap';

// $font: $ff-aller, $ff-base;
// $font-it: $ff-aller-italic;
// $font-b: $ff-aller-regular-bold;
// $font-b-it: $ff-aller-bold-italic;
// $font-light-it: $ff-aller-light-italic;