.form-message {
    margin-top: 0;
    span {
        display: block;
        margin-bottom: 15px;
    }
    .success {
        color: #3bc180;
    }
    .error {
        color: #f94e4e;
    }
}