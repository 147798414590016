.action-area {
    padding-top: 65px;
    .row {
        .action-right-wrapper {
            margin-top: 20px;
        }
        @include breakpoint (medium) {
            display: flex;
            align-items: center;
            > div:last-child {
                text-align: right;
            }
            .action-right-wrapper {
                margin-top: 0;
            }
        }
    }
    .action-wrapper.mb-30 {
        margin-bottom: 0;
    }
    .action-right-wrapper {
        overflow: auto;
        padding-top: 0;
        &.mb-30 {
            margin-bottom: 0;
        }
    }
}

.slider-content h1 {
    font-size: 65px;
}

.service-area {
    .service-img img {
        width: auto;
        transition: .3s;
        height: 500px;
        max-width: none;
    }
    .service-icon-img img {
        width: 47px;
    }
    .row {
        > div:nth-of-type(2) .service-img img,
        > div:nth-of-type(3) .service-img img,
        > div:nth-of-type(5) .service-img img {
            margin-left: -200px;
        }
        > div:nth-of-type(6) {
            .service-icon-img img {
                width: 60px;
            }
            .service-img img {
                margin-left: -400px;
            }
        }
    }
}