.action-right-wrapper a,
#contact-form > button,
.contact-right-wrapper button {
    border: 2px solid $white;
    border-radius: 30px;
    color: $white;
    display: inline-block;
    font-size: 15px;
    font-weight: 600;
    line-height: 1;
    padding: 12px;
    transition: .3s;
    &:hover {
        background: $white;
        color: $light-blue;
    }
    @include breakpoint (large) {
        padding: 12px 40px;
    }
}

#contact-form > button,
.contact-right-wrapper button {
    border: 2px solid $light-blue;
    &:hover {
        border: 2px solid $dark-blue;
    }
}

.contact-right-wrapper button {
    margin-left: 15px;
    color: $light-blue;
    @extend %transition-ease-long;
    &:hover {
        border: 2px solid $light-blue;
        background: $light-blue;
        color: white;
    }
}

#contact-form > button:hover,
.get-quote a:hover {
    background: $dark-blue;
}

#contact-form > button:hover {
    color: $white;
}