$white: #ffffff;
$black: #11141D;
$black-blue: #171923;

$grey-light: #eeeeee;
$grey-lighter: #f4f4f4;
$grey-lightest: #f8f8f8;
$grey: #999999;
$grey-dark: #777777;
$grey-darker: #333333;

$dark-blue: #24408F;
// $light-blue: #52b6bc;
$light-blue: #52b6bc;